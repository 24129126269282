import PropTypes from 'prop-types';
import React from 'react';
import { Card, Icon } from 'semantic-ui-react';

import { useComponentOptions } from '../../../../config/design.context';
import { EntityTags } from '../../../EntityTags';
import SquareImage from '../../../SquareImage';
import { SocialNetworks } from '../../SpeakerCard/components';

const ClassicSpeakerHeader = ({ fluid, template, speaker, ...rest }) => {
  const {
    company,
    description,
    firstName,
    lastName,
    role,
    thumbnail,
    url,
    phoneNumber,
    email,
    shortDescription,
    socialNetworks,
  } = speaker;

  const { tagFields = [] } = rest;
  const isLightTemplate = template === 'light';
  const imageProps = useComponentOptions('thumbnails');

  return (
    <Card className="card--speaker" fluid={fluid}>
      {thumbnail && (
        <div>
          <SquareImage
            as="img"
            src={thumbnail}
            alt={`${firstName} ${lastName}`}
            className="avatar large"
            imageSize={200}
            {...imageProps}
          />
        </div>
      )}
      <Card.Content className="content">
        <Card.Header className="field-fullname">
          {firstName} {lastName}
        </Card.Header>
        {!isLightTemplate && (
          <Card.Description className="field-company">{company}</Card.Description>
        )}
        <Card.Meta>
          <span className="date">{role} </span>
        </Card.Meta>
        {email && (
          <Card.Meta>
            <Icon name="mail" />
            <a href={`mailto:${email}`} className="field-mail">
              {email}
            </a>
          </Card.Meta>
        )}
        {phoneNumber && (
          <Card.Meta>
            <Icon name="phone" />
            <a href={`tel:${phoneNumber}`} className="field-phone">
              {phoneNumber}
            </a>
          </Card.Meta>
        )}
        {url && (
          <Card.Meta>
            <Icon name="linkify" />
            <a href={url} rel="noreferrer noopener" className="field-link" target="_blank">
              {url}{' '}
            </a>
          </Card.Meta>
        )}
        <Card.Description>
          <SocialNetworks socialNetworks={socialNetworks} />
        </Card.Description>
        <EntityTags tagFields={tagFields} entity={speaker} type="speaker" />
        {shortDescription && (
          <Card.Description
            style={{ textAlign: 'center', marginTop: 10 }}
            className="field-short-description"
          >
            <p>{shortDescription}</p>
          </Card.Description>
        )}
        {description && !isLightTemplate && (
          <Card.Description
            style={{ textAlign: 'justify', marginTop: 20 }}
            className="field-description"
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Card.Description>
        )}
      </Card.Content>
    </Card>
  );
};

ClassicSpeakerHeader.defaultProps = {
  fluid: undefined,
  template: undefined,
};
ClassicSpeakerHeader.propTypes = {
  fluid: PropTypes.bool,
  template: PropTypes.string,
  speaker: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    role: PropTypes.string,
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    url: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    socialNetworks: PropTypes.array,
  }).isRequired,
};

export default ClassicSpeakerHeader;
