import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userAgenda } from '../../../../agenda/store/agenda.selectors';
import { groupEventsByDays } from '../../../../utils/agendaUtils';
import { formatDateForUserTimezone } from '../../../../utils/date';
import { Day } from '../types/program.types';

export function useProgramAgenda(mode: 'agenda' | 'custom', defaultDays: Day[] = []): Day[] {
  let days = defaultDays;
  const sessions = useSelector(userAgenda);

  if (mode === 'agenda') {
    const { days: dayKeys, eventsByDay } = groupEventsByDays(sessions);
    days = dayKeys.map((dayKey) => {
      return {
        _id: dayKey,
        date: dayKey,
        items: (eventsByDay[dayKey] || []).map((session) => ({
          _id: session._id,
          time: session.customTime || `${formatDateForUserTimezone(session.startDate)}`,
          title: session.title,
          subtitle: session.shortDescription,
          location: session.location,
          description: session.description,
          icon: session.icon,
          image: session.image,
        })),
      } as Day;
    });
  }
  return useMemo(
    () =>
      orderBy(
        days.filter((d) => !!d.date),
        'date',
        'asc',
      ),
    [days],
  );
}
