import { useConfig } from '../config/config.context';
import i18n from '../translations/i18n';
import { useIsDesktop } from './useMediaQuery';

export function useMustBeUsedOnMobileOnly(): {
  mustBeUsedOnMobileOnly: boolean;
  text: string;
} {
  const config = useConfig();
  const { availability } = config;
  const isDesktop = useIsDesktop();
  return {
    mustBeUsedOnMobileOnly: isDesktop && availability?.support === 'mobileOnly',
    text: availability?.text || i18n.t('common.only-mobile-text'),
  };
}
