import { FC } from 'react';
import { Container, Segment } from 'semantic-ui-react';

import Styles from '../Styles';
import CdnImage from '../components/CdnImage';
import PageBackground from '../components/PageBackground';
import { useDesignConfig } from '../config/design.context';
import { useMustBeUsedOnMobileOnly } from '../hooks/useMustBeUsedOnMobileOnly';
import store from '../shared/Store';
import './NotAvailableOnDesktopScreen.scss';

function useDefaultBackground() {
  const isConnected = store.isLoggedIn();
  const { loginBackground, homeBackground } = useDesignConfig();
  return isConnected ? homeBackground : loginBackground ?? homeBackground;
}

export const NotAvailableOnDesktopScreen: FC = () => {
  const { text } = useMustBeUsedOnMobileOnly();
  const { logo, primaryColor: color } = useDesignConfig();
  const background = useDefaultBackground();
  return (
    <>
      <Styles />
      <div className="not-available-on-desktop">
        <PageBackground image={background} color={color} />
        <Container text>
          <Segment>
            <CdnImage className="logo" maxHeight={100} src={logo} as="img" />
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </Segment>
        </Container>
      </div>
    </>
  );
};
