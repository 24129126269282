/* eslint-disable react/require-default-props */
import cx from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Modal } from 'semantic-ui-react';
import sift, { Query } from 'sift';

import { CmsBlocks } from '..';
import { useParticipant } from '../../../core/hooks/useParticipant';
import { UserType } from '../../../core/users/users.types';
import ProfileActionButton from '../../../profile/components/ProfileActionButton';
import UserProfileDetail from '../../../profile/components/UserProfileDetail/UserProfileDetail';
import store from '../../../shared/Store';
import { sweetAlert } from '../../../utils/popupUtils';
import CdnImage from '../../CdnImage';
import { Image } from '../types';
import { ProfileField, isProfileField } from './ProfileField';
import './UserProfileModal.scss';

type UserProfileModalProps = {
  title?: string;
  blocks?: Record<string, any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: Array<ProfileField | string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayCondition: Query<any>;
  design?: {
    inverted?: boolean;
  };
  header?: {
    image?: Image;
  };
  preBlocks?: Record<string, any>[];
};

const translationPrefix = 'profile';

const UserProfileModalBlock = ({
  design,
  fields = [],
  blocks,
  header,
  displayCondition,
  title,
  preBlocks,
}: UserProfileModalProps): React.ReactElement | null => {
  const user = useParticipant();
  const displayConditionTester = sift(displayCondition);
  const isVisible = displayConditionTester(user);
  const [userPatch, setUserPatch] = useState<Partial<UserType>>({});
  const [openedModal, setOpenedModal] = useState(isVisible);
  const { t } = useTranslation();

  useEffect(() => {
    if (!user) return;
    setOpenedModal(isVisible);
  }, [isVisible]);

  const patchedUser = { ...user, ...userPatch };
  const allFieldsSet = !fields.some((f) => isProfileField(f) && f.required && !patchedUser[f.key]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUserChange = (modifiedUser: Record<string, any>) => {
    setUserPatch({
      ...userPatch,
      ...modifiedUser,
    });
  };

  const saveUser = async () => {
    try {
      await store.updateUser({
        ...userPatch,
        updatedByUserAt: moment().toISOString(),
      });

      setOpenedModal(false);
      await sweetAlert({
        text: t(`${translationPrefix}.confirm.update-success`),
        icon: 'success',
      });
    } catch (e) {
      await sweetAlert({
        text: t(`${translationPrefix}.errors.update-profile`),
        icon: 'error',
      });
    }
  };

  if (!openedModal || !isVisible) {
    return null;
  }

  return (
    <Modal open className={cx('modal--user-profile', design?.inverted && 'modal--inverted')}>
      <Modal.Content>
        {header?.image && <CdnImage maxWidth={300} src={header?.image} />}
        <CmsBlocks blocks={preBlocks} />
        <Grid>
          <UserProfileDetail
            user={patchedUser}
            design={design}
            fields={fields}
            title={title}
            editing
            onFieldChange={onUserChange}
            onUpdateUser={onUserChange}
            actions={[
              <ProfileActionButton
                id="updateProfile"
                key="updateProfile"
                position="right"
                disabled={!allFieldsSet}
                primary
                onClick={saveUser}
                icon={undefined}
                label={undefined}
                to={undefined}
              />,
            ]}
          />
        </Grid>
        <CmsBlocks blocks={blocks} />
      </Modal.Content>
    </Modal>
  );
};

export default UserProfileModalBlock;
