import { format, parseISO } from 'date-fns';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import pl from 'date-fns/locale/pl';
import pt from 'date-fns/locale/pt';
import br from 'date-fns/locale/pt-BR';
import ro from 'date-fns/locale/ro';
import sk from 'date-fns/locale/sk';
// import moment from 'moment';
// import 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import moment from 'moment-timezone';
import 'moment/locale/br';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import 'moment/locale/ro';
import 'moment/locale/sk';

import i18n from '../translations/i18n';

const languageKeys = {
  br: 'pt-br',
};

function mapLanguage(lng) {
  return languageKeys[lng] || lng;
}

moment.locale(mapLanguage(window.__DATA__?.lang) || 'fr');

const { eventTimezone, forceForEveryone } = window.__DATA__?.options?.timezone || {};

export const momentTz =
  eventTimezone && forceForEveryone ? (date) => moment(date).tz(eventTimezone) : moment;

export function forceTimezone() {
  return eventTimezone && forceForEveryone;
}

export const locales = { fr, en, es, de, nl, br, mo: ro, pl, pt, ro, sk, it };

export function dateFNSLocale() {
  return locales[(window.__DATA__ || {}).lang] || fr;
}

export function ensureDate(date) {
  if (typeof date === 'string') return parseISO(date);
  return new Date(date);
}

export function formatDateForUserTimezone(date) {
  if (!date) return '';
  return momentTz(ensureDate(date)).format(i18n.t('common.time', { defaultValue: 'HH[h]mm' }));
}

export function localeFormat(date, dateFormat) {
  if (!date) return '';
  return format(ensureDate(date), dateFormat, { locale: dateFNSLocale() });
}

export function getTimeZone() {
  if (Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions) {
    return Intl.DateTimeFormat().resolvedOptions();
  }
  return undefined;
}

export function isDateWithoutTime(date) {
  if (!date) return false;
  return !!date.match(/^\d{4}-\d{2}-\d{2}$/);
}
