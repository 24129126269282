import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Dropdown, Icon, Menu } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import { useCurrentPage } from '../../Layouts/VideoBackground/hooks';
import { useForceUpdate } from '../../authentication/hooks';
import { useConfig } from '../../config/config.context';
import { useDesignConfig } from '../../config/design.context';
import { useScreenConfig } from '../../config/screens.context';
import { bem } from '../../core/design/bem';
import { eventTags } from '../../core/trackers/events';
import { useIsMobile } from '../../hooks/useMediaQuery';
import { useDisconnectionMode } from '../../networking/hooks/useDisconnectionMode';
import { useMe } from '../../profile/hooks';
import store from '../../shared/Store';
import { evalValue } from '../../utils/constraints';
import { handleChangeLanguage, makeLanguagePage } from '../../utils/urlUtils';
import CdnImage from '../CdnImage';
import ExpiringNavLink from '../ExpiringNavLink';
import ImageIcon from '../ImageIcon';
import { ensureTarget } from '../cms/CTATileBlock/utils';
import './AppSecondaryMenu.scss';
import LogoutButton, { Avatar } from './LogoutButton';
import MenuSearchBar from './MenuSearchBar';
import NavMenuItem from './NavMenuItem';
import { ScannerItem } from './ScannerItem/ScannerItem';
import MessagesPopup from './widgets/MessagesPopup/MessagesPopup';
import NotificationsPopup from './widgets/NotificationsPopup/NotificationsPopup';

const css = bem('secondary-menu');
const langClass = bem('lang');

const ProfileItem = ({ path }) => {
  const user = useSelector((state) => state.user.user);
  if (!user) return null;

  return (
    <Menu.Item className={css('item--profile').toString()} as={NavLink} to={path}>
      <Avatar user={user} />
      <span className="firstName">{user.firstName}</span>
      {user?.lastName && <span className="lastName"> {user.lastName}</span>}
    </Menu.Item>
  );
};

ProfileItem.propTypes = {
  path: PropTypes.string.isRequired,
};

export async function handleChangeTimezone(value) {
  await store.updateUser({ timezone: value });
}

const TimezoneItems = ({ className }) => {
  const { timezones, defaultTimezone } = useConfig();
  const user = useMe();

  if (!timezones) return null;
  return (
    <Menu.Item className={className}>
      <Dropdown
        className="timezones"
        placeholder="Timezone"
        selection
        compact
        options={timezones}
        value={user?.timezone || defaultTimezone}
        onChange={(_e, { value }) => handleChangeTimezone(value)}
      />
    </Menu.Item>
  );
};

TimezoneItems.propTypes = {
  className: PropTypes.string,
};

TimezoneItems.defaultProps = {
  className: '',
};

const LanguagesItem = ({ className, variant }) => {
  const { lang, languages } = useConfig();
  const hasMultipleLanguages = languages && languages.length > 1;
  if (!hasMultipleLanguages) return null;

  if (variant === 'horizontal') {
    return (
      <Menu.Item className={`item--languages item--languages-horizontal ${className || ''}`}>
        {languages.map((lng) => (
          <span
            key={lng.key}
            className={langClass({ [lng.value]: true })
              .state({ active: lng.value === lang })
              .toString()}
          >
            <a href={makeLanguagePage(lng.value)}>{lng.value}</a>
          </span>
        ))}
      </Menu.Item>
    );
  }
  return (
    <Menu.Item className={className}>
      <Dropdown
        className="languages"
        placeholder="Language"
        compact
        selection
        options={languages}
        value={lang}
        onChange={handleChangeLanguage}
      />
    </Menu.Item>
  );
};

LanguagesItem.defaultProps = {
  className: '',
  variant: 'dropdown',
};

LanguagesItem.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['horizontal', 'dropdown']),
};

export const PageItem = (props) => {
  const { entry, count, className, config, onClick } = props;
  const { trackEvent } = useTracking();
  const { _id, label, pageId, icon, url, path, style = {}, target } = entry;
  const { style: iconStyle, maxHeight = 40 } = config;
  return (
    <NavMenuItem
      className={className}
      to={url || path}
      key={_id || pageId}
      exact={false}
      onClick={() => {
        trackEvent(eventTags.MENU_CLICK, entry);
        if (onClick) {
          onClick();
        }
      }}
      style={{ color: '#FFF', cursor: 'pointer', ...style }}
      target={ensureTarget(target, url || path)}
    >
      <ImageIcon
        icon={icon}
        style={{
          height: maxHeight,
          ...iconStyle,
        }}
        maxHeight={maxHeight}
      />
      {label && (
        <label style={{ cursor: 'pointer' }} htmlFor={pageId}>
          {label}
        </label>
      )}
      {count > 0 ? <span className={css('counter')}>{count}</span> : undefined}
    </NavMenuItem>
  );
};

PageItem.propTypes = {
  entry: PropTypes.object.isRequired,
  className: PropTypes.string,
  config: PropTypes.object,
  count: PropTypes.number,
  onClick: PropTypes.func,
};

PageItem.defaultProps = {
  className: '',
  config: {},
  count: undefined,
  onClick: undefined,
};

export const SecondaryMenuEntry = ({ entry, config }) => {
  const user = useMe();
  const { _id, type, pageId, icon, condition, name } = entry;
  const isVisible = condition ? evalValue(condition, { user }) : true;
  const { trackEvent } = useTracking();
  const page = useScreenConfig(pageId);
  const forceUpdate = useForceUpdate();
  const mobile = useIsMobile();
  const { isPossible } = useDisconnectionMode();

  if (!isVisible) return null;
  if (pageId && type === 'page' && (!page || isEmpty(page))) return null; // Hidden

  const className = css('item', { _id, pageId, type, name }).mix(entry.className).toString();
  switch (type) {
    case 'search': {
      const { searchConfig } = entry;
      return (
        <Menu.Item className={className} key={_id || pageId} style={{ color: '#FFF' }}>
          <MenuSearchBar searchConfig={searchConfig} />
        </Menu.Item>
      );
    }
    case 'languages': {
      return <LanguagesItem key={_id} className={className} {...entry} />;
    }
    case 'messages': {
      return <MessagesPopup icon={icon} {...entry} />;
    }
    case 'notifications': {
      return <NotificationsPopup icon={icon} {...entry} />;
    }
    case 'social': {
      const { social = [], circular = false } = entry;
      return (
        <Menu.Item className={className}>
          {social.map((s) => {
            const { icon: socialIcon, url, color, style } = s;
            return (
              <a href={url} key={url} target="_blank" rel="noreferrer">
                <Button
                  icon
                  circular={circular}
                  color={color}
                  name={socialIcon}
                  style={{ padding: 10, ...style }}
                  onClick={() => trackEvent(eventTags.MENU_CLICK, s)}
                >
                  <Icon name={socialIcon} style={{ fontSize: 18 }} />
                </Button>
              </a>
            );
          })}
        </Menu.Item>
      );
    }
    case 'scanner': {
      return <ScannerItem entry={entry} className={className} config={config} />;
    }
    case 'profile': {
      return <ProfileItem {...entry} />;
    }
    case 'timezones': {
      return <TimezoneItems key={_id} className={className} {...entry} />;
    }
    case 'logout': {
      if (!isPossible) return null;

      const handleDisconnect = async () => {
        await store.disconnect();
        forceUpdate();
        window.location.reload();
      };

      if (mobile) {
        return (
          <PageItem
            entry={entry}
            config={config}
            className={className}
            onClick={handleDisconnect}
          />
        );
      }

      const { config: logoutConfig } = entry;
      return <LogoutButton menuConfig={logoutConfig} onClick={handleDisconnect} />;
    }
    case 'button':
    case 'page': {
      return <PageItem entry={entry} config={config} className={className} />;
    }
    default:
      return null;
  }
};
SecondaryMenuEntry.defaultProps = {
  config: {},
};
SecondaryMenuEntry.propTypes = {
  config: PropTypes.object,
  entry: PropTypes.object.isRequired,
};

const AppSecondaryMenu = ({ menu }) => {
  const { secondaryLogo, logoProps = {}, secondaryLogoHeight = 80 } = useDesignConfig();
  const { logoPath = '/', altText = '' } = logoProps;
  const currentPage = useCurrentPage();
  if (!menu) return null;
  const { entries, config = {} } = menu;
  const { showCurrentPage, showHomeIcon, homeIcon = 'home', homeIconProps } = config;

  if (!entries?.length) return null;

  return (
    <div className={css('container')}>
      <div className={css('logoAndPageName')}>
        {showHomeIcon && (
          <ExpiringNavLink to="/">
            <ImageIcon icon={homeIcon} className="home" maxHeight={20} {...homeIconProps} />
          </ExpiringNavLink>
        )}
        <ExpiringNavLink to={logoPath}>
          <CdnImage
            as="img"
            src={secondaryLogo}
            className="logo--secondary"
            maxHeight={secondaryLogoHeight}
            alt={altText}
            {...logoProps}
          />
        </ExpiringNavLink>
        {showCurrentPage && currentPage?.name && (
          <div className="currentPage">
            <span> | </span> {currentPage?.name}
          </div>
        )}
      </div>
      <Menu secondary>
        {entries.map((entry) => (
          <SecondaryMenuEntry key={entry._id} entry={entry} config={config} />
        ))}
      </Menu>
    </div>
  );
};

AppSecondaryMenu.defaultProps = {
  menu: undefined,
};
AppSecondaryMenu.propTypes = {
  menu: PropTypes.object,
};

export default AppSecondaryMenu;
