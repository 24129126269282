import compact from 'lodash/compact';
import merge from 'lodash/merge';

import i18n from '../../translations/i18n';
import { setIn } from '../../utils/object.utils';
import { CookieCategories } from './cookies.types';

export function getCookieConsentName(eventId: string): string {
  return `cc_cookie_site_${eventId}`;
}

export function getCookieConsent(eventId: string): { categories: CookieCategories[] } {
  const name = getCookieConsentName(eventId);
  try {
    const consent = localStorage.getItem(name);
    if (!consent) return { categories: [] };
    return JSON.parse(consent);
  } catch (e) {
    return { categories: [] };
  }
}

function getTranslatedMergedObject(key: string): Record<string, any> {
  const common = i18n.t(key, { returnObjects: true, ns: 'common' }) as any;
  const custom = i18n.t(key, { returnObjects: true, ns: 'custom' }) as any;
  return merge({}, common, custom);
}

const getGoogleAvailableServices = () => {
  const { services } = window.__DATA__?.cookieBanner ?? {};

  const hasGoogleMapsIframe = services?.includes('google-maps');
  const hasGoogleAnalytics = services?.includes('google-analytics');
  return { hasGoogleMapsIframe, hasGoogleAnalytics };
};

function getCookieBannerTexts(): any {
  // eslint-disable-next-line no-restricted-globals
  const { cookieBanner } = window.__DATA__;

  const rawTranslations = getTranslatedMergedObject('cookieBanner');
  const { texts } = cookieBanner ?? {};

  const siteTranslations: Record<string, any> = merge({}, rawTranslations, texts);
  const { cookieSections, ...translations } = siteTranslations;
  const { hasGoogleMapsIframe, hasGoogleAnalytics } = getGoogleAvailableServices();

  return setIn(
    translations,
    ['preferencesModal', 'sections'],
    compact([
      cookieSections.usage,
      cookieSections.necessary,
      // cookieSections['cookie-consent'],
      // cookieSections.function,
      hasGoogleMapsIframe && cookieSections['google-maps'],
      hasGoogleAnalytics && cookieSections['google-analytics'],
      // ...window.__DATA__.cookies.extraSections,
      cookieSections['more-info'],
    ]),
  );
}

export function getCookieConsentConfig(
  eventId: string,
  lang: string,
): Pick<CookieConsent.CookieConsentConfig, 'cookie' | 'guiOptions' | 'categories' | 'language'> {
  const { hasGoogleMapsIframe, hasGoogleAnalytics } = getGoogleAvailableServices();

  return {
    cookie: {
      name: `cc_cookie_site_${eventId}`,
      // domain: window.location.hostname,
      // path: '/',
      expiresAfterDays: 182,
      sameSite: 'Lax',
      useLocalStorage: true,
    },
    guiOptions: {
      consentModal: {
        layout: 'box wide',
        position: 'bottom left',
        equalWeightButtons: true,
        flipButtons: true,
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: true,
      },
    },
    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true, // this category cannot be disabled
      },
      // functionality: {},
      // set categories only if they are enabled
      ...(hasGoogleMapsIframe && { 'google-maps': {} }),
      ...(hasGoogleAnalytics && { 'google-analytics': {} }),
    },
    language: {
      default: lang,
      translations: { [lang]: getCookieBannerTexts() },
    },
  };
}
